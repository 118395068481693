export default [
  {
    path: '/daily-parking',
    name: 'services-daily-parking',
    component: () => import('@/views/parking/daily-parking/DailyParking.vue'),
    meta: {
      title: 'Daily Parking',
    },
  },
  {
    path: '/seasonal-parking',
    name: 'services-seasonal-parking',
    component: () => import('@/views/users/user-groups/UserGroupList.vue'),
    meta: {
      title: 'Seasonal Parking',
    },
  },
  {
    path: '/business',
    name: 'services-sbp',
    component: () => import('@/views/sbp/SingleBusinessPermit.vue'),
    meta: {
      title: 'Businesses',
      breadcrumb: [
        {
          text: 'Services',
        },
        {
          text: 'SBP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/business/:id',
    name: 'services-business-details',
    component: () => import('@/views/sbp/business-details/BusinessDetailsView.vue'),
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/transactions/TransactionsList.vue'),
    meta: {
      title: 'Transactions',
      breadcrumb: [
        {
          text: 'Transactions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'user-profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      title: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
]
